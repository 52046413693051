* {
    scroll-behavior: smooth;
}

select {
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-bootstrap-table table {
    table-layout: auto !important;
}

.react-bootstrap-table thead th {
    padding: 13px !important;
    background: var(--bs-gray-100) !important;
    font-weight: 900 !important;
    font-size: 14px !important;
    color: var(--bs-text-muted) !important;
}

.react-bootstrap-table thead th:first-child {
    border-bottom-left-radius: var(--bs-border-radius) !important;
    border-top-left-radius: var(--bs-border-radius) !important;
}

.react-bootstrap-table thead th:last-child {
    border-bottom-right-radius: var(--bs-border-radius) !important;
    border-top-right-radius: var(--bs-border-radius) !important;
}

.react-bootstrap-table tbody td {
    padding: 10px 13px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.loaderBtn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

.loaderBtn.progress1::before {
    animation: progress 20s ease-in-out forwards;
}

@keyframes progress {
    0% {
        left: -100%;
    }

    20% {
        left: -80%;
    }

    30% {
        left: -72%;
    }

    60% {
        left: -50%;
    }

    90% {
        left: -10%;
    }

    100% {
        left: 100%;
    }
}

.ant-select-item-option-content,
.ant-select-selection-item {
    text-transform: capitalize;
}

.shadow-inner {
    box-shadow: inset var(--bs-primary) 0px 0px 20px -8px;
}

.dropdownToggleRemove .dropdown-toggle::after {
    display: none !important;
}

.dropdownToggleRemove .dropdown-menu {
    min-width: 100px !important;
}

.card_main {
    width: 350px;
    height: 180px;
    background: url('/public/media/images/payment_card_bg.png');
    background-size: cover;
    background-position: bottom right;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    color: lightgray;
}